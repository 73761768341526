<template>
  <v-container fluid>
    <v-row >
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>{{agreement ? agreement.title : null}}</v-toolbar-title>
          </v-toolbar>
          <v-expand-transition>
            <v-card-text v-if="agreement">
              <div class="caption">
                Version: {{agreement.version}}<span class="mx-3">|</span>Last modified: {{agreement.dtModified | unixToLocalDt}}
              </div>
              <!-- STEP 1-->
                <v-card class="mb-3">
                  <v-card-title>
                    Step 1 of 2
                  </v-card-title>
                  <v-card-text>
                    Please read the folowing agreement.
                    <div v-if="isText">
                      <v-textarea
                        solo
                        :value="agreement.text"
                        readonly
                        auto-grow
                      ></v-textarea>
                    </div>
                    <div v-else>
                      Please click the button below, to read the agreement.
                    </div>
                  </v-card-text>
                  <v-expand-transition>
                    <v-card-actions v-if="!isText">
                      <v-btn color="color3 color3Text--text" @click.stop="linkClick">
                        <v-icon class="mr-3">fas fa-external-link</v-icon>
                        View Agreement
                      </v-btn>
                    </v-card-actions>
                  </v-expand-transition>
                </v-card>
              <!-- STEP 2-->
                <v-card>
                  <v-card-title>
                    Step 2 of 2
                  </v-card-title>
                  <v-card-text>
                    Fill out the form below and submit you signature.
                    <v-alert type="error" :value="!enabled && showSubmit" prominent text transition="scale-transition">
                      You must view the agreement before continuing
                    </v-alert>
                    <v-text-field
                      class="mt-3"
                      label="IP Address"
                      v-model="myData.ip"
                      readonly
                      hide-details
                      :disabled="!enabled"
                    ></v-text-field>
                    <v-text-field
                      label="Athlete's Name"
                      v-model="myData.playerName"
                      readonly
                      hide-details
                      :disabled="!enabled"
                    ></v-text-field>
                    <v-radio-group v-model="myData.over18" row :disabled="!enabled">
                      <template v-slot:label>
                        <div>Is {{myData.playerName}} over 18 years old?</div>
                      </template>
                      <v-radio color="color3" :value="true" label="Yes"></v-radio>
                      <v-radio color="color3" :value="false" label="No"></v-radio>
                    </v-radio-group>
                    <v-expand-transition>
                      <v-checkbox
                        v-if="!myData.over18"
                        :disabled="!enabled"
                        color="color3"
                        :label="`I am ${myData.playerName}'s Parent/Legal Guardian`"
                        v-model="myData.isParent"
                      ></v-checkbox>
                    </v-expand-transition>
                    <v-checkbox
                      :disabled="!enabled"
                      color="color3"
                      :label="`I have read and agree to the ${agreement.title}`"
                      v-model="myData.agreed"
                    ></v-checkbox>
                    <v-text-field
                      label="Signature"
                      hint="Please type your name to sign the agreement"
                      v-model="myData.signature"
                      persistent-hint
                      color="color3"
                      :disabled="!enabled"
                    ></v-text-field>
                    <v-alert type="success" :value="complete" prominent text transition="scale-transition">
                      You're all set! Thank you
                    </v-alert>
                    <v-alert type="info" :value="alreadySigned" prominent text transition="scale-transition">
                      This agreement has already been signed
                    </v-alert>
                  </v-card-text>
                  <v-expand-transition>
                    <v-card-actions class="justify-center" v-if="showSubmit">
                      <v-btn
                        color="color3 color3Text--text"
                        :disabled="!valid"
                        :loading="loading"
                        @click.stop="postSignature"
                      >
                        Submit Signature
                      </v-btn>
                    </v-card-actions>
                  </v-expand-transition>
                </v-card>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      loading: false,
      myData: null,
      linkClicked: false,
      complete: false,
      loadingText: false,
      textFile: null
    }
  },
  computed: {
    ...mapGetters(['subdomain']),
    valid () {
      return this.enabled && (this.myData.over18 || this.myData.isParent) && this.myData.agreed && this.myData.signature
    },
    agreement () {
      return this.myData && this.myData.agreement
    },
    isText () {
      return this.agreement && this.agreement.url.endsWith('.txt')
    },
    enabled () {
      return (this.isText || this.linkClicked) && this.showSubmit
    },
    alreadySigned () {
      return this.myData && this.myData.dtSigned
    },
    showSubmit () {
      return !this.complete && !this.alreadySigned
    }
  },
  methods: {
    linkClick () {
      if (this.agreement) {
        this.linkClicked = true
        window.open(this.agreement.url, '_blank')
      }
    },
    loadMe () {
      this.loading = true
      this.$VBL.agreements.player.get(this.id)
        .then(r => { this.myData = r.data })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    postSignature () {
      this.loading = true
      this.$VBL.agreements.player.post(this.id, this.myData)
        .then(r => { this.complete = true })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    }
  },
  mounted () {
    this.loadMe()
  }
}
</script>

<style scoped>

</style>
